import { lazy } from 'react';
import { Redirect, Route } from 'react-router';

import GenerateOrderWrapper from './pages/GenerateOrder/GenerateOrderWrapper';
import { withSecurityLevel } from '@echtech/iron-core';

import accessRegistry from './services/accessRegistry';

const AgreementRequest = lazy(
  () => import('./pages/AgreementRequest/AgreementRequestWrapper')
);
const AgreementRequestsList = lazy(
  () =>
    import(
      './pages/MyAgreementRequests/AgreementsWithCustomers/AgreementRequests'
    )
);
const Address = lazy(() => import('./pages/Addresses'));
const AreasList = lazy(() => import('./pages/Areas'));
const BusinessSettings = lazy(() => import('./pages/BusinessSettings'));
const DayClosingProcess = lazy(
  () => import('./pages/DayClosingProcess/DayClosingProcessWrapper')
);
const ConfirmAndPay = lazy(
  () => import('./pages/ConfirmAndPay/WithConfirmAndPay')
);
const CreditRequests = lazy(() => import('./pages/CreditRequests'));
const DeliveryAddress = lazy(() => import('./pages/DeliveryAddress'));
const ExpirationCharges = lazy(() => import('./pages/ExpirationCharges'));
const GenerateOrder = lazy(() => import('./pages/GenerateOrder'));
const GeneratePdf = lazy(() => import('./pages/Orders/GeneratePdf/ordersPdf'));
const HomePage = lazy(() => import('./pages/HomePage'));
const InventoryAdjustment = lazy(() => import('./pages/InventoryAdjustment'));
const InventoryMaxMinEditor = lazy(
  () => import('./pages/InventoryMaxMinEditor')
);
const InventoryViewer = lazy(() => import('./pages/InventoryViewer'));
const InvoicingInfo = lazy(
  () => import('./pages/InvoicingInformation/ListInvoicingInfo')
);
const IronServices = lazy(() => import('./pages/IronServices'));
const ListSupplierExpenses = lazy(() => import('./pages/MonthlyExpenses'));
const ModificationOItem = lazy(() => import('./pages/ModificationOItem'));
const MyCreditRequests = lazy(() => import('./pages/MyCreditRequests'));
const NewContact = lazy(() => import('./pages/Contact/NewContact/index'));
const NewCredit = lazy(() => import('./pages/NewCredit'));
const NewExpensesUnique = lazy(
  () => import('./pages/MonthlyExpenses/NewExpensesOnce')
);
const NewInvoicingInfo = lazy(() => import('./pages/InvoicingInformation'));
const NewPack = lazy(() => import('./pages/NewPack'));
const NewPresentation = lazy(() => import('./pages/NewPresentation'));
const Task = lazy(() => import('./pages/Tasks/TaskEditor/WithTask'));
const SaleDetail = lazy(() => import('./pages/SaleDetail'));
const OrderDetail = lazy(() => import('./pages/OrderDetail'));
const Orders = lazy(() => import('./pages/Orders'));
const PlannedCheckList = lazy(
  () =>
    import('./pages/Tasks/PlannedTasks/PlannedChecklist/WithPlannedChecklist')
);
const PlannedTasksViewer = lazy(
  () =>
    import(
      './pages/Tasks/PlannedTasks/PlannedTasksViewer/WithPlannedTasksViewer'
    )
);
const Presentations = lazy(() => import('./pages/Presentations'));
const ProblemSolution = lazy(
  () => import('./pages/Issues/ProblemIssuesSeller/IssuesSolution')
);
const ProblemsRefunds = lazy(() => import('./pages/Issues'));
const ProductConversions = lazy(
  () => import('./pages/ProductConversions/WithConversions')
);
const ProductConverter = lazy(() => import('./pages/ProductConverter'));
const NewProduct = lazy(() => import('./pages/ProductEditor/CreateProduct'));
const UpdateProduct = lazy(() => import('./pages/ProductEditor/UpdateProduct'));
const ProductList = lazy(() => import('./pages/ProductList'));
const NewPromo = lazy(() => import('./pages/Promotions/NewPromo'));
const UpdatePromo = lazy(
  () => import('./pages/Promotions/UpdatePromo/WithPromoDatabase')
);
const PromotionsList = lazy(
  () => import('./pages/PromotionsList/WithPromotions')
);
const AppliedPromotion = lazy(
  () => import('./pages/PromotionsList/AppliedPromotions/WithPromotion')
);
const PurchaseNeeds = lazy(() => import('./pages/PurchaseNeeds'));
const WithQuotesList = lazy(() => import('./pages/QuotesList/WithQuotesList'));
const ReportedProblems = lazy(() => import('./pages/Issues/MyReportedIssues'));
const WithSupplierSalesCatalog = lazy(
  () => import('./pages/SalesCatalog/WithSupplierSalesCatalog')
);
const SalesGeneratePdf = lazy(
  () => import('./pages/SalesOrders/GeneratePdf/saleOrdersPdf')
);
const SalesOrders = lazy(() => import('./pages/SalesOrders'));
const SalesProblemReport = lazy(
  () => import('./pages/Issues/ProblemIssuesSeller')
);
const ShoppingList = lazy(() => import('./pages/ShoppingList'));
const SubMenu = lazy(() => import('./pages/SubMenu/index'));
const SupplierAccounts = lazy(() => import('./pages/SupplierAccounts'));
const SupplierService = lazy(
  () => import('./pages/SupplierServices/WithSupplierServices')
);
const SystemInboxPage = lazy(() => import('./pages/SystemInboxPage'));
const TaskViewer = lazy(
  () => import('./pages/Tasks/TaskViewer/WithTaskViewer')
);
const PlannedTaskEditor = lazy(
  () => import('./pages/Tasks/PlannedTasks/PlannedTasksEditor/WithPlannedTask')
);
const UserManagement = lazy(() => import('./pages/UserManagement/WithContact'));
const Wastage = lazy(() => import('./pages/Wastages/WithSystemOption'));
const WastagesHistory = lazy(() => import('./pages/WastagesHistory'));
const WithAccessLevelInfo = lazy(() => import('./pages/AccessLevelInfo'));
const WithCreditsGranted = lazy(() => import('./pages/CreditsGranted'));
const WithMyCredits = lazy(() => import('./pages/MyCredits'));
const WithPaymentMethods = lazy(() => import('./pages/PaymentMethods'));
// const WithSupplier = lazy(() => import('./pages/OrderServices/WithSupplier'));
const SearchTags = lazy(() => import('./pages/SearchTags'));
const CreditChargesAndHolds = lazy(
  () => import('./pages/CreditChargesAndHolds')
);
const ModifyCreditData = lazy(
  () => import('./pages/CreditsGranted/ModifyCredit/Data')
);
const InitialInventory = lazy(() => import('./pages/InitialInventory/index'));
const WithOrderItemsToConfirm = lazy(
  () => import('./pages/PendingToConfirm/WithOrderItemsToConfirm')
);
const WithOrderItemsToSupply = lazy(
  () => import('./pages/PendingToSupply/WithOrderItemsToSupply')
);
const WithUpdateContact = lazy(
  () => import('./pages/Contact/WithUpdateContact')
);
const AddressData = lazy(() => import('./pages/Addresses/NewAddress'));
const DeliveryAddressData = lazy(
  () => import('./pages/DeliveryAddress/NewDeliveryAddress/DeliveryAddressData')
);
const NewArea = lazy(() => import('./pages/Areas/NewAreas/NewAreas'));
const TrackingIssue = lazy(() => import('./pages/Issues/TrackingIssue'));
const NewIssues = lazy(() => import('./pages/Issues/NewIssues'));
const OrderSupplys = lazy(() => import('./pages/OrderSupplys'));
const CreditRequestData = lazy(
  () => import('./pages/CreditRequests/AnalyzeCredit/Data')
);
const MultipleCarts = lazy(() => import('./pages/MultipleCarts'));
const IndividualCharges = lazy(() => import('./pages/IndividualCharges'));
const FixedExpenses = lazy(() => import('./pages/PlannedExpenses'));
const NewFixedExpenses = lazy(
  () => import('./pages/PlannedExpenses/NewPlannedExpenses')
);
const NewSupplierAccount = lazy(
  () => import('./pages/SupplierAccounts/NewSupplierAccount')
);
const WithUpdateSupplierAccount = lazy(
  () =>
    import(
      './pages/SupplierAccounts/UpdateSupplierAccount/WithUpdateSupplierAccount'
    )
);

const MyAgreementRequests = lazy(
  () =>
    import(
      './pages/MyAgreementRequests/AgreementsWithSuppliers/MyAgreementRequests'
    )
);

const AnalyzeAgreementRequestData = lazy(
  () =>
    import(
      './pages/MyAgreementRequests/AgreementRequestsSeller/AnalyzeAgreementRequest/Data'
    )
);

const WaitingToPickupOrders = lazy(
  () => import('./pages/WaitingToPickupOrders')
);
const OnDeliveryOrders = lazy(() => import('./pages/OnDeliveryOrders'));
const WithOnDeliveryOrderDetail = lazy(
  () => import('./pages/OnDeliveryOrderDetail/WithOnDeliveryOrderDetail')
);

const SubscriptionPlans = lazy(
  () => import('./pages/Subscription/SubscriptionPlans/SubscriptionDataLoader')
);

const InventoryMovements = lazy(() => import('./pages/InventoryMovements/'));
const WalletData = lazy(() => import('./pages/Wallet/Data'));

const SupplierServiceRates = lazy(
  () => import('./pages/SupplierServiceRates/WithSupplierServicesProvider')
);
const WithShoppingListForEntries = lazy(
  () => import('./pages/OtherEntries/index')
);
const ExecuteExpense = lazy(() => import('./pages/ExecuteExpense'));
const InventoryCosts = lazy(() => import('./pages/InventoryCosts'));
const AccountMovements = lazy(() => import('./pages/AccountMovements/'));
const WithReceiveInvoice = lazy(
  () => import('./pages/ReceiveInvoice/WithReceiveInvoice')
);

const WithMailboxInvoicesAndReceipts = lazy(
  () =>
    import('./pages/MailboxInvoicesAndReceipts/WithMailboxInvoicesAndReceipts')
);

const WithCheckouts = lazy(() => import('./pages/CashDesks/withCheckouts'));

const OpenCloseCashDesk = lazy(
  () => import('./pages/OpenCloseCashDesk/WithSupplierCashDesk')
);

const WithPaymentsReceivedDetails = lazy(
  () => import('./pages/PaymentsReceivedDetails/WithPaymentsReceivedDetails')
);

const Deliverymen = lazy(() => import('./pages/Deliverymen/WithDeliverymen'));

const Return = lazy(() => import('./pages/Return/WithReturn'));
const MultipleTags = lazy(() => import('./pages/MultipleTags/index'));

const InventoryOption = lazy(() => import('./pages/InventoryOptions/'));

const CostHistory = lazy(() => import('./pages/CostHistory'));
const SalesPriceHistory = lazy(() => import('./pages/SalesPriceHistory'));
const TransferAreas = lazy(() => import('./pages/TransferAreas'));
const IamProducer = lazy(() => import('./pages/IamProducer/index'));
const WithSupplierConfiguraton = lazy(
  () => import('./pages/ConfigureLots/WithSupplierConfiguraton')
);
const ExpenseProofReview = lazy(() => import('./pages/ExpenseProofReview'));
const ExpenseProofEditor = lazy(() => import('./pages/ExpenseProofEditor'));
const WithMyExpenses = lazy(() => import('./pages/MyExpenses/WithMyExpenses'));
const WithChats = lazy(() => import('./pages/Chats/WithChats'));
const HistoryCloseDay = lazy(
  () => import('./pages/HistoryCloseDay/WithHistoryCloseDay')
);
const WithCurrentSubscriptionPlans = lazy(
  () => import('./pages/Subscription/CurrentSubscription/WithSubscriptionPlan')
);
const DetailedInventory = lazy(() => import('./pages/DetailedInventory'));

const InitialTaskGenerator = lazy(() => import('./pages/InitialTaskGenerator'));
const WithRecipes = lazy(() => import('./pages/Recipes/WithRecipes'));

const Recipe = lazy(() => import('./pages/Recipe/WithRecipe'));

const WithProcedure = lazy(() => import('./pages/Procedure/WithProcedure'));
const Ingredients = lazy(() => import('./pages/Ingredients/withIngredients'));
const RecipeProcedure = lazy(
  () =>
    import(
      './pages/RecipeProcedureAndSteps/RecipeProcedure/WithRecipeProcedure'
    )
);
const WithRecipeSteps = lazy(
  () => import('./pages/RecipeProcedureAndSteps/RecipeSteps/WithRecipeSteps')
);

const RecipeView = lazy(() => import('./pages/RecipeView/WithRecipeView'));
const MyPreviousList = lazy(
  () => import('./pages/MyPreviousList/WithMyPreviousList')
);
const WithSupplierPrevious = lazy(
  () => import('./pages/SupplierPrevious/WithSupplierPrevious')
);

const WithMenus = lazy(() => import('./pages/Menus/WithMenus'));
const WithMenu = lazy(() => import('./pages/Menu/WithMenu'));

export const ironRoutes = [
  <Route path="/" key="/" exact component={HomePage} />,
  <Route
    path="/dayClosingProcess"
    key="/dayClosingProcess"
    component={withSecurityLevel(DayClosingProcess, accessRegistry)}
  />,
  <Route
    path="/confirmAndPay/:from/:draftKey?/:orderId?"
    key="/confirmAndPay"
    component={withSecurityLevel(ConfirmAndPay, accessRegistry)}
  />,
  <Route
    path="/orders"
    key="/orders"
    component={withSecurityLevel(Orders, accessRegistry)}
  />,
  <Route
    path="/mySales"
    key="/mySales"
    component={withSecurityLevel(SalesOrders, accessRegistry)}
  />,
  <Route
    path="/quotes"
    key="/quotes"
    component={withSecurityLevel(WithQuotesList, accessRegistry)}
  />,
  <Route
    path="/purchaseNeeds/:supplierPresentationId"
    key="/purchaseNeeds"
    component={withSecurityLevel(PurchaseNeeds, accessRegistry)}
  />,
  <Route
    path="/saleDetail/:orderId"
    key="/saleDetail"
    component={withSecurityLevel(SaleDetail, accessRegistry)}
  />,
  <Route
    path="/shoppingList"
    key="/shoppingList"
    component={withSecurityLevel(ShoppingList, accessRegistry)}
  />,
  <Route
    path="/orderDetail/:orderId"
    key="/orderDetail"
    component={withSecurityLevel(OrderDetail, accessRegistry)}
  />,
  <Route
    path="/promotion/new"
    key="/promotion/new"
    component={withSecurityLevel(NewPromo, accessRegistry)}
  />,
  <Route
    path="/promotion/update/:promotionId"
    key="/promotion/update"
    component={withSecurityLevel(UpdatePromo, accessRegistry)}
  />,
  <Route
    path="/applied-promotion/:promotionId"
    key="/applied-promotion"
    component={withSecurityLevel(AppliedPromotion, accessRegistry)}
  />,
  <Route
    path="/products/:from/:sellerById/:currencyCode/:serviceId?/:deliveryInstructionId?/:pickupAddressId?"
    key="/products"
    component={withSecurityLevel(ProductList, accessRegistry)}
  />,
  <Route
    path="/productsConverter"
    key="/productsConverter"
    component={withSecurityLevel(ProductConverter, accessRegistry)}
  />,
  <Route
    path="/orderPdf/:orderId"
    key="/orderPdf"
    component={withSecurityLevel(GeneratePdf, accessRegistry)}
  />,
  <Route
    path="/salesPdf/:orderId"
    key="/salesPdf"
    component={withSecurityLevel(SalesGeneratePdf, accessRegistry)}
  />,
  <Route
    path="/presentations/:from/:sellerById/:productId/:currencyCode/:serviceId?/:deliveryInstructionId?/:pickupAddressId?"
    key="/presentations"
    component={withSecurityLevel(Presentations, accessRegistry)}
  />,
  <Route
    path="/modificationOItem/:from/:presentationId/:sellerById/:currencyCode/:serviceId?/:deliveryInstructionId?/:pickupAddressId?"
    key="/modificationOItem"
    component={withSecurityLevel(ModificationOItem, accessRegistry)}
  />,
  <Route
    path="/salesCatalog"
    key="/salesCatalog"
    component={withSecurityLevel(WithSupplierSalesCatalog, accessRegistry)}
  />,
  <Route
    path="/productsCatalog"
    key="/productsCatalog"
    component={withSecurityLevel(WithSupplierSalesCatalog, accessRegistry)}
  />,
  <Route
    path="/inventory/maxMin/:supplierPresentationId"
    key="/inventory/maxMin"
    component={withSecurityLevel(InventoryMaxMinEditor, accessRegistry)}
  />,
  <Route
    path="/newPresentation"
    key="/newPresentation"
    component={withSecurityLevel(NewPresentation, accessRegistry)}
  />,
  <Route
    path="/newPack"
    key="/newPack"
    component={withSecurityLevel(NewPack, accessRegistry)}
  />,
  <Route
    path="/newTask/:taskId?"
    key="/newTask"
    component={withSecurityLevel(Task, accessRegistry)}
  />,
  <Route
    path="/inventoryViewer"
    key="/inventoryViewer"
    component={withSecurityLevel(InventoryViewer, accessRegistry)}
  />,
  <Route
    path="/configure-product/new"
    key="/configure/product/new"
    component={withSecurityLevel(NewProduct, accessRegistry)}
  />,
  <Route
    path="/configure-product/update/:supplierPresentationId?"
    key="/configure/product/update"
    component={withSecurityLevel(UpdateProduct, accessRegistry)}
  />,
  <Route
    path="/problemsRefunds"
    key="/problemsRefunds"
    component={withSecurityLevel(ProblemsRefunds, accessRegistry)}
  />,
  <Route
    path="/plannedChecklist/:plannedTaskId"
    key="/plannedChecklist"
    component={withSecurityLevel(PlannedCheckList, accessRegistry)}
  />,
  <Route
    path="/plannedTasks/viewer"
    key="/plannedTasks/viewer"
    component={withSecurityLevel(PlannedTasksViewer, accessRegistry)}
  />,
  <Route
    path="/plannedTasks/editor/:id?"
    key="/plannedTasks/editor"
    component={withSecurityLevel(PlannedTaskEditor, accessRegistry)}
  />,
  <Route
    path="/reportedProblems"
    key="/reportedProblems"
    component={withSecurityLevel(ReportedProblems, accessRegistry)}
  />,
  <Route
    path="/salesProblemReport"
    key="/salesProblemReport"
    component={withSecurityLevel(SalesProblemReport, accessRegistry)}
  />,
  <Route
    path="/systemInbox/:messageId?"
    key="/systemInbox"
    component={withSecurityLevel(SystemInboxPage, accessRegistry)}
  />,
  <Route path="/subMenu/:category" key="/subMenu" exact component={SubMenu} />,
  <Route
    path="/wastages"
    key="/wastages"
    component={withSecurityLevel(Wastage, accessRegistry)}
  />,
  <Route
    path="/schedule"
    key="/schedule"
    component={withSecurityLevel(SupplierService, accessRegistry)}
  />,
  <Route
    path="/businessSettings"
    key="/businessSettings"
    component={withSecurityLevel(BusinessSettings, accessRegistry)}
  />,
  <Route
    path="/promotionsList"
    key="/promotionsList"
    component={withSecurityLevel(PromotionsList, accessRegistry)}
  />,
  <Route
    path="/wastagesHistory"
    key="/wastagesHistory"
    component={withSecurityLevel(WastagesHistory, accessRegistry)}
  />,
  <Route
    path="/productConversions"
    key="/productConversions"
    component={withSecurityLevel(ProductConversions, accessRegistry)}
  />,
  <Route
    path="/taskViewer"
    key="/taskViewer"
    component={withSecurityLevel(TaskViewer, accessRegistry)}
  />,
  <Route
    path="/problemSolution/:receptionOrderId"
    key="/problemSolution"
    component={withSecurityLevel(ProblemSolution, accessRegistry)}
  />,
  <Route
    path="/address/:supplierId?"
    key="/address"
    component={withSecurityLevel(Address, accessRegistry)}
  />,
  <Route
    path="/newAddress/:id?/:supplierId?"
    key="/newAddress"
    component={withSecurityLevel(AddressData, accessRegistry)}
  />,
  <Route
    path="/areaList"
    key="/areaList"
    component={withSecurityLevel(AreasList, accessRegistry)}
  />,
  <Route
    path="/deliveryAddress"
    key="/deliveryAddress"
    component={withSecurityLevel(DeliveryAddress, accessRegistry)}
  />,
  <Route
    path="/newdDeliveryAddress/:id?"
    key="/newdDeliveryAddress"
    component={withSecurityLevel(DeliveryAddressData, accessRegistry)}
  />,
  <Route
    path="/myCreditRequests"
    key="/myCreditRequests"
    component={withSecurityLevel(MyCreditRequests, accessRegistry)}
  />,
  <Route
    path="/creditRequests"
    key="/creditRequests"
    component={withSecurityLevel(CreditRequests, accessRegistry)}
  />,
  <Route
    path="/newCredit"
    key="/newCredit"
    component={withSecurityLevel(NewCredit, accessRegistry)}
  />,
  <Route
    path="/userManagement"
    key="/userManagement"
    component={withSecurityLevel(UserManagement, accessRegistry)}
  />,
  <Route
    path="/withUpdateContact/:contactId"
    key="/withUpdateContact"
    component={withSecurityLevel(WithUpdateContact, accessRegistry)}
  />,
  <Route
    path="/newContact"
    key="/newContact"
    component={withSecurityLevel(NewContact, accessRegistry)}
  />,
  <Route
    path="/myCredits"
    key="/myCredits"
    component={withSecurityLevel(WithMyCredits, accessRegistry)}
  />,
  <Route
    path="/creditsGranted"
    key="/creditsGranted"
    component={withSecurityLevel(WithCreditsGranted, accessRegistry)}
  />,
  <Route
    path="/accessLevelInfo"
    key="/accessLevelInfo"
    component={withSecurityLevel(WithAccessLevelInfo, accessRegistry)}
  />,
  <Route
    path="/services"
    key="/services"
    component={withSecurityLevel(IronServices, accessRegistry)}
  />,
  <Route
    path="/paymentMethods"
    key="/paymentMethods"
    component={withSecurityLevel(WithPaymentMethods, accessRegistry)}
  />,
  <Route
    path="/creditChargesAndHolds/:creditId"
    key="/creditChargesAndHolds"
    component={withSecurityLevel(CreditChargesAndHolds, accessRegistry)}
  />,
  <Route
    path="/modifyCredit/:creditId"
    key="/modifyCredit"
    component={withSecurityLevel(ModifyCreditData, accessRegistry)}
  />,
  <Route
    path="/searchTags"
    key="/searchTags"
    component={withSecurityLevel(SearchTags, accessRegistry)}
  />,
  <Route
    path="/initialInventory"
    key="/initialInventory"
    component={withSecurityLevel(InitialInventory, accessRegistry)}
  />,
  <Route
    path="/pendingToConfirm"
    key="/pendingToConfirm"
    component={withSecurityLevel(WithOrderItemsToConfirm, accessRegistry)}
  />,
  <Route
    path="/pendingToSupply"
    key="/pendingToSupply"
    component={withSecurityLevel(WithOrderItemsToSupply, accessRegistry)}
  />,
  <Route
    path="/inventoryAdjustment"
    key="/inventoryAdjustment"
    component={withSecurityLevel(InventoryAdjustment, accessRegistry)}
  />,
  <Route
    path="/newArea/:areaId?"
    key="/newArea"
    component={withSecurityLevel(NewArea, accessRegistry)}
  />,
  <Route
    path="/trackingIssue/:receptionOrderId/:client"
    key="/trackingIssue"
    component={withSecurityLevel(TrackingIssue, accessRegistry)}
  />,
  <Route
    path="/newIssues/:receptionOrderId"
    key="/newIssues"
    component={withSecurityLevel(NewIssues, accessRegistry)}
  />,
  <Route
    path="/orderSupplys"
    key="/orderSupplys"
    component={withSecurityLevel(OrderSupplys, accessRegistry)}
  />,
  <Route
    path="/analyzeCredit/:id"
    key="/analyzeCredit"
    component={withSecurityLevel(CreditRequestData, accessRegistry)}
  />,
  <Route
    path="/multipleCarts/:from"
    key="/multipleCarts"
    component={withSecurityLevel(MultipleCarts, accessRegistry)}
  />,
  <Route
    path="/individualCharges/:creditId"
    key="/individualCharges"
    component={withSecurityLevel(IndividualCharges, accessRegistry)}
  />,
  <Route
    path="/fixedExpenses"
    key="/fixedExpenses"
    component={withSecurityLevel(FixedExpenses, accessRegistry)}
  />,
  <Route
    path="/newFixedExpenses/:fixedExpensesId?"
    key="/newFixedExpenses"
    component={withSecurityLevel(NewFixedExpenses, accessRegistry)}
  />,
  <Route
    path="/myAgreementRequestsList"
    key="/myAgreementRequestsList"
    component={withSecurityLevel(MyAgreementRequests, accessRegistry)}
  />,
  <Route
    path="/generateOrder/:from/:draftKey?/:supplierId?/:serviceId?/:addressId?"
    key="/generateOrder"
    component={withSecurityLevel(GenerateOrder, accessRegistry)}
  />,
  <Route
    path="/agreementRequest/:creditId/:latePaydayLimit"
    key="/agreementRequest"
    component={withSecurityLevel(AgreementRequest, accessRegistry)}
  />,
  <Route
    path="/agreementRequests/seller"
    key="/agreementRequests/seller"
    component={withSecurityLevel(AgreementRequestsList, accessRegistry)}
  />,
  <Route
    path="/analyzeAgreementRequest/:paymentAgreementId"
    key="/analyzeAgreementRequest"
    component={withSecurityLevel(AnalyzeAgreementRequestData, accessRegistry)}
  />,
  <Route
    path="/expirationCharges/:creditId"
    key="/expirationCharges"
    component={withSecurityLevel(ExpirationCharges, accessRegistry)}
  />,
  <Route
    path="/listMonthlyExpenses"
    key="/listMonthlyExpenses"
    component={withSecurityLevel(ListSupplierExpenses, accessRegistry)}
  />,
  <Route
    path={[
      '/newOneTimeExpense/:supplierExpensesId?/readOnly/:readOnly?',
      '/newOneTimeExpense/:supplierExpensesId?/cashDelivered/:cashDelivered?',
      '/newOneTimeExpense/:supplierExpensesId?/:readOnly?/:cashDelivered?',
    ]}
    key="/newOneTimeExpense"
    component={withSecurityLevel(NewExpensesUnique, accessRegistry)}
  />,
  <Route
    path="/newInvoicingInfo/:invoicingInfoId?"
    key="/newInvoicingInfo"
    component={withSecurityLevel(NewInvoicingInfo, accessRegistry)}
  />,
  <Route
    path="/invoicingInformation"
    key="/invoicingInformation"
    component={withSecurityLevel(InvoicingInfo, accessRegistry)}
  />,
  <Route
    path="/supplierAccounts"
    key="/supplierAccounts"
    component={withSecurityLevel(SupplierAccounts, accessRegistry)}
  />,
  <Route
    path="/newSupplierAccount"
    key="/newSupplierAccount"
    component={withSecurityLevel(NewSupplierAccount, accessRegistry)}
  />,
  <Route
    path="/withUpdateSupplierAccount/:supplierAccountId"
    key="/withUpdateSupplierAccount"
    component={withSecurityLevel(WithUpdateSupplierAccount, accessRegistry)}
  />,
  <Route
    path="/waitingToPickupOrders"
    key="waitingToPickupOrders"
    component={withSecurityLevel(WaitingToPickupOrders, accessRegistry)}
  />,
  <Route
    path="/onDeliveryOrders"
    key="onDeliveryOrders"
    component={withSecurityLevel(OnDeliveryOrders, accessRegistry)}
  />,
  <Route
    path="/onDeliveryOrderDetail/:orderId/:status"
    key="onDeliveryOrderDetail"
    component={withSecurityLevel(WithOnDeliveryOrderDetail, accessRegistry)}
  />,
  <Route
    path={['/subscriptionPlans', '/subscriptionPlansInfo']}
    key="subscriptionPlans"
    component={withSecurityLevel(SubscriptionPlans, accessRegistry)}
  />,
  <Route
    path="/inventory-movements"
    key="inventory-movements"
    component={withSecurityLevel(InventoryMovements, accessRegistry)}
  />,
  <Route
    path="/wallet"
    key="wallet"
    component={withSecurityLevel(WalletData, accessRegistry)}
  />,
  <Route
    path="/supplierService/rates"
    key="supplierService/rates"
    component={withSecurityLevel(SupplierServiceRates, accessRegistry)}
  />,
  <Route
    path="/otherEntries"
    key="otherEntries"
    component={withSecurityLevel(WithShoppingListForEntries, accessRegistry)}
  />,
  <Route
    path="/execute-expense/:id"
    key="execute-expense"
    component={withSecurityLevel(ExecuteExpense, accessRegistry)}
  />,
  <Route
    path="/inventory-costs"
    key="inventory-costs"
    component={withSecurityLevel(InventoryCosts, accessRegistry)}
  />,
  <Route
    path="/account-movements/:accountId"
    key="account-movements"
    component={withSecurityLevel(AccountMovements, accessRegistry)}
  />,
  <Route
    path="/receiveInvoice/:supplierExpenseId"
    key="/receiveInvoice"
    component={withSecurityLevel(WithReceiveInvoice, accessRegistry)}
  />,
  <Route
    path="/invoicesAndReceiptsMailbox"
    key="invoicesAndReceiptsMailbox"
    component={withSecurityLevel(
      WithMailboxInvoicesAndReceipts,
      accessRegistry
    )}
  />,
  <Route
    path="/withCheckouts"
    key="withCheckouts"
    component={withSecurityLevel(WithCheckouts, accessRegistry)}
  />,
  <Route
    path="/generateOrderWrapper/:from/:draftKey?/:omitDraft?"
    key="/generateOrderWrapper"
    component={withSecurityLevel(GenerateOrderWrapper, accessRegistry)}
  />,
  <Route
    path="/openCloseCashDesk"
    key="/openCloseCashDesk"
    component={withSecurityLevel(OpenCloseCashDesk, accessRegistry)}
  />,
  <Route
    path="/return/:receptionOrderId/:parentRoId?/:gift?"
    key="/return"
    component={withSecurityLevel(Return, accessRegistry)}
  />,
  <Route
    path="/paymentsReceivedDetails/:contactId"
    key="/paymentsReceivedDetails"
    component={withSecurityLevel(WithPaymentsReceivedDetails, accessRegistry)}
  />,
  <Route
    path="/deliverymen/list"
    key="/deliverymen/list"
    component={withSecurityLevel(Deliverymen, accessRegistry)}
  />,
  <Route
    path="/multipleTags"
    key="/multipleTags"
    component={withSecurityLevel(MultipleTags, accessRegistry)}
  />,
  <Route
    path="/inventoryOptions"
    key="/inventoryOptions"
    component={withSecurityLevel(InventoryOption, accessRegistry)}
  />,
  <Route
    path="/costHistory"
    key="/costHistory"
    component={withSecurityLevel(CostHistory, accessRegistry)}
  />,
  <Route
    path="/salesPriceHistory"
    key="/salesPriceHistory"
    component={withSecurityLevel(SalesPriceHistory, accessRegistry)}
  />,
  <Route
    path="/transferAreas"
    key="/transferAreas"
    component={withSecurityLevel(TransferAreas, accessRegistry)}
  />,
  <Route
    path="/iamProducer"
    key="/iamProducer"
    component={withSecurityLevel(IamProducer, accessRegistry)}
  />,
  <Route
    path="/configureLots"
    key="/configureLots"
    component={withSecurityLevel(WithSupplierConfiguraton, accessRegistry)}
  />,
  <Route
    path="/myExpenses"
    key="/myExpenses"
    component={withSecurityLevel(WithMyExpenses, accessRegistry)}
  />,
  <Route
    path="/expenseProofReview/:supplierExpenseId"
    key="/expenseProofReview"
    component={withSecurityLevel(ExpenseProofReview, accessRegistry)}
  />,
  <Route
    path="/expenseProofEditor/:supplierExpenseId/:expenseProofId?"
    key="/expenseProofEditor"
    component={withSecurityLevel(ExpenseProofEditor, accessRegistry)}
  />,
  <Route
    path="/chats"
    key="/chats"
    component={withSecurityLevel(WithChats, accessRegistry)}
  />,
  <Route
    path="/historyCloseDay"
    key="/historyCloseDay"
    component={withSecurityLevel(HistoryCloseDay, accessRegistry)}
  />,
  <Route
    path="/currentSubscriptionPlan"
    key="/currentSubscriptionPlan"
    component={withSecurityLevel(WithCurrentSubscriptionPlans, accessRegistry)}
  />,
  <Route
    path="/initialTaskGenerator"
    key="/initialTaskGenerator"
    component={withSecurityLevel(InitialTaskGenerator, accessRegistry)}
  />,
  <Route
    path="/detailedInventory"
    key="/detailedInventory"
    component={withSecurityLevel(DetailedInventory, accessRegistry)}
  />,
  <Route
    path="/recipes"
    key="/recipes"
    component={withSecurityLevel(WithRecipes, accessRegistry)}
  />,
  <Route
    path="/recipe/:recipeId?"
    key="/recipe"
    component={withSecurityLevel(Recipe, accessRegistry)}
  />,
  <Route
    path="/procedure/:recipeId"
    key="/procedure"
    component={withSecurityLevel(WithProcedure, accessRegistry)}
  />,
  <Route
    path={[
      '/ingredients/:stepId/recipeId/:recipeId',
      '/ingredients/:stepId/ingredient-id/:ingredientId',
    ]}
    key="/ingredients"
    component={withSecurityLevel(Ingredients, accessRegistry)}
  />,
  <Route
    path="/recipeProcedure/:recipeId"
    key="/recipeProcedure"
    component={withSecurityLevel(RecipeProcedure, accessRegistry)}
  />,
  <Route
    path="/recipeView/:recipeId"
    key="/recipeView"
    component={withSecurityLevel(RecipeView, accessRegistry)}
  />,
  <Route
    path="/recipeSteps/:recipeId"
    key="/recipeSteps"
    component={withSecurityLevel(WithRecipeSteps, accessRegistry)}
  />,
  <Route
    path="/myPreviousList"
    key="/myPreviousList"
    component={withSecurityLevel(MyPreviousList, accessRegistry)}
  />,
  <Route
    path="/supplierPrevious/:supplierPreviousId?"
    key="/supplierPrevious"
    component={withSecurityLevel(WithSupplierPrevious, accessRegistry)}
  />,
  <Route
    path="/menus"
    key="/menus"
    component={withSecurityLevel(WithMenus, accessRegistry)}
  />,
  <Route
    path="/menu/:menuId?"
    key="/menu"
    component={withSecurityLevel(WithMenu, accessRegistry)}
  />,
  <Route key="redirect" render={() => <Redirect to="/" />} />,
];

export default ironRoutes;
